<template>
  <div>
    <!-- 위험등록부 목록 -->
    <c-table
      ref="tableRisk"
      title="LBL0001203"
      tableId="riskRegister01"
      :columns="gridRisk.columns"
      :data="riskReduce.riskbooks"
      :merge="gridRisk.merge"
      gridHeightAuto
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
    >
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='riskHazardPicture'">
          <q-icon
            style="font-size: 30px; cursor:pointer;"
            class="text-success" 
            name="image"
            @click.stop="openHazardPicture(props)" />
          <!-- <c-upload-picture
            class="scenario4m"
            height="35px"
            :attachInfo="{
              isSubmit: '',
              taskClassCd: 'RISK_HAZARD',
              taskKey: props.row.ramRiskHazardId,
            }"
          ></c-upload-picture> -->
        </template>
        <template v-else-if="col.name==='picture'">
          <q-icon
            style="font-size: 30px; cursor:pointer;"
            class="text-primary" 
            name="image"
            @click.stop="openImprPicture(props)" />
        </template>
        <template v-else-if="col.name==='customCol'">
          <component
            :is="imprComponent"
            :col="col"
            :props="props"
            :inputEditable="false"
            :requestContentsCols="requestContentsCols"
            tableKey="ramJsaAssessScenarioId"
            ibmTaskTypeCd="ITT0000015"
            ibmTaskUnderTypeCd="ITTU000020"
            @imprChange="imprChange"
          />
        </template>
      </template>
    </c-table>
    <!-- 개선 목록 -->
    <c-table
      ref="tableImpr"
      title="LBL0001174"
      tableId="impr01"
      :columns="gridImpr.columns"
      :data="riskReduce.imprs"
      :merge="gridImpr.merge"
      gridHeightAuto
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      @linkClick="linkClick"
    >
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='riskHazardPicture'">
          <q-icon
            style="font-size: 30px; cursor:pointer;"
            class="text-success" 
            name="image"
            @click.stop="openHazardPicture(props)" /> 
          <!-- <c-upload-picture
            class="scenario4m"
            height="35px"
            :attachInfo="{
              isSubmit: '',
              taskClassCd: 'RISK_HAZARD',
              taskKey: props.row.ramRiskHazardId,
            }"
          ></c-upload-picture> -->
        </template>
        <template v-else-if="col.name==='picture'">
          <q-icon
            style="font-size: 30px; cursor:pointer;"
            class="text-primary" 
            name="image"
            @click.stop="openImprPicture(props)" />
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'jsa-risk-register-TL',
  props: {
    param: {
      type: Object,
      default: function() {
        return {
          ramRiskAssessmentPlanId: '',
          ramStepCd: '',
        }
      },
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      riskReduce: {
        imprs: [],
        riskbooks: [],
      },
      gridRisk: {
        merge: [
          { index: 0, colName: 'processName' },
          { index: 1, colName: 'processJob' },
          { index: 2, colName: 'processJobStep' },
          { index: 3, colName: 'processJobStepHazard' },
          { index: 4, colName: 'processJobStepHazard' },
        ],
        columns: [
          {
            name: 'processName',
            field: 'processName',
            // 공정
            label: 'LBLPROCESS',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'jobName',
            field: 'jobName',
            // 작업
            label: 'LBLJOB',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'jobStepName',
            field: 'jobStepName',
            description: 'jobStepDesc',
            // 작업단계
            label: 'LBL0001103',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'riskHazardPicture',
            field: 'riskHazardPicture',
            // 사진
            label: 'LBL0001175',
            align: 'center',
            style: 'width:40px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'riskHazardName',
            field: 'riskHazardName',
            // 분류-유해위험요인
            label: 'LBL0001020',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'relationLaw',
            field: 'relationLaw',
            // 관련법규
            label: 'LBL0001021',
            align: 'left',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceCause',
            field: 'riskOccurrenceCause',
            // 원인
            label: 'LBL0001006',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceResult',
            field: 'riskOccurrenceResult',
            // 결과
            label: 'LBL0001007',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            // 현재안전조치
            label: 'LBL0001025',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'before',
            field: 'before',
            // 개선전
            label: 'LBL0001008',
            align: 'center',
            child: [
              {
                name: 'beforeFrequency',
                field: 'beforeFrequency',
                // 빈도
                label: 'LBL0001009',
                align: 'center',
                style: 'width:40px',
                sortable: false
              },
              {
                name: 'beforeStrength',
                field: 'beforeStrength',
                // 강도
                label: 'LBL0001010',
                align: 'center',
                style: 'width:40px',
                sortable: false
              },
              {
                name: 'beforeRisk',
                field: 'beforeRisk',
                // 위험도
                label: 'LBL0001011',
                align: 'center',
                style: 'width:50px',
                type: 'proxy',
                sortable: false,
              },
            ]
          },
          {
            name: 'picture',
            field: 'picture',
            label: '개선 전/후 사진',
            align: 'center',
            type: 'custom',
            style: 'width:70px',
            sortable: false,
          },
          {
            name: 'improvementMeasures',
            field: 'improvementMeasures',
            // 개선대책
            label: 'LBL0001408',
            align: 'left',
            style: 'width:150px',
            sortable: false
          },
          {
            name: 'impr',
            field: 'impr',
            // 개선진행
            label: 'LBL0001178',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                label: 'LBLIMPRNO_PROGRESS',
                align: 'center',
                style: 'width:150px',
                type: 'custom',
                sortable: false
              },
            ]
          },
          {
            name: 'after',
            field: 'after',
            // 개선후
            label: 'LBL0001012',
            align: 'center',
            child: [
              {
                name: 'afterFrequency',
                field: 'afterFrequency',
                // 빈도
                label: 'LBL0001009',
                align: 'center',
                style: 'width:40px',
                sortable: false
              },
              {
                name: 'afterStrength',
                field: 'afterStrength',
                // 강도
                label: 'LBL0001010',
                align: 'center',
                style: 'width:40px',
                sortable: false
              },
              {
                name: 'afterRisk',
                field: 'afterRisk',
                // 위험도
                label: 'LBL0001011',
                align: 'center',
                style: 'width:50px',
                type: 'proxy',
                sortable: false,
              },
            ]
          },
          {
            name: 'assessDate',
            field: 'assessDate',
            // 평가일
            label: 'LBL0001013',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'assessUserName',
            field: 'assessUserName',
            // 평가자
            label: 'LBL0001014',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
        ],
        data: [],
        height: 'auto' //'500px'
      },
      gridImpr: {
        merge: [
          { index: 0, colName: 'processName' },
          { index: 1, colName: 'processJob' },
          { index: 2, colName: 'processJobStep' },
          { index: 3, colName: 'processJobStepHazard' },
          { index: 4, colName: 'processJobStepHazard' },
          { index: 5, colName: 'ramJsaAssessScenarioId' },
          { index: 6, colName: 'ramJsaAssessScenarioId' },
          { index: 7, colName: 'ramJsaAssessScenarioId' },
          { index: 8, colName: 'ramJsaAssessScenarioId' },
          { index: 9, colName: 'ramJsaAssessScenarioId' },
        ],
        columns: [],
        height: '500px'
      },
      imprComponent: () => import(`${'@/pages/common/ibm/tableImpr.vue'}`),
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      requestImprRow: null,
      imprData: null,
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    setheight() {
      let pxRemove = this.height.replace('px', '');
      return this.height && this.height.indexOf('px') > -1 && !isNaN(pxRemove) ? (Number(pxRemove) - 20) + 'px' : '500px'
    },
    requestContentsCols() {
      return ['jobStepName', 'riskHazardNameClass', 'riskOccurrenceCause'];
    }
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.listUrl = selectConfig.ram.jsa.riskReduce.list.url;
      // code setting
      this.$comm.getStepItems('IBM_STEP_CD').then(_result => {
        this.gridImpr.columns = [
          {
            name: 'processName',
            field: 'processName',
            // 공정
            label: 'LBLPROCESS',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'jobName',
            field: 'jobName',
            // 작업
            label: 'LBLJOB',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'jobStepName',
            field: 'jobStepName',
            description: 'jobStepDesc',
            // 작업단계
            label: 'LBL0001103',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'riskHazardPicture',
            field: 'riskHazardPicture',
            // 사진
            label: 'LBL0001175',
            align: 'center',
            style: 'width:40px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'riskHazardName',
            field: 'riskHazardName',
            // 분류-유해위험요인
            label: 'LBL0001020',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceCause',
            field: 'riskOccurrenceCause',
            // 원인
            label: 'LBL0001006',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceResult',
            field: 'riskOccurrenceResult',
            // 결과
            label: 'LBL0001007',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            // 현재안전조치
            label: 'LBL0001025',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'picture',
            field: 'picture',
            label: '개선 전/후 사진',
            align: 'center',
            type: 'custom',
            style: 'width:70px',
            sortable: false,
          },
          {
            name: 'improvementMeasures',
            field: 'improvementMeasures',
            // 개선대책
            label: 'LBL0001408',
            align: 'left',
            style: 'width:150px',
            sortable: false
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            // 제목
            label: 'LBLTITLE',
            align: 'left',
            type: 'link',
            style: 'width:240px',
            sortable: true,
          },
          {
            name: 'ibmStepCd',
            field: 'ibmStepCd',
            // 진행상태
            label: 'LBLPROGRESS',
            align: 'center',
            style: 'width:80px',
            type: 'tag',
            colorItems: _result,
            sortable: false
          },
          {
            name: 'improveRequest',
            field: 'improveRequest',
            // 요청부서정보
            label: '요청부서정보',
            align: 'center',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'actionCompleteRequestDate',
            field: 'actionCompleteRequestDate',
            // 조치완료요청일
            label: 'LBL0001017',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            // 조치부서
            label: 'LBL0001018',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ]
      });
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId
      }
      this.$http.request((_result) => {
        this.$_.extend(this.riskReduce, _result.data);
      },);
    },
    imprChange() {
      this.$refs['tableRisk'].$refs['compo-table'].resetVirtualScroll();
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.imprData = row;
      this.popupOptions.title = 'LBLIMPROVE';
      this.popupOptions.param = {
        sopImprovementId: row.sopImprovementId,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        this.$_.extend(this.imprData, result.col1)
        this.$refs['tableImpr'].$refs['compo-table'].resetVirtualScroll();
      }
    },
    openImpr(data, row) {
      this.requestImprRow = row
      this.imprData = data;
      this.popupOptions.title = 'LBLIMPROVE';
      this.popupOptions.param = {
        sopImprovementId: data.sopImprovementId,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup2;
    },
    closeImprPopup2(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
        let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
        if (splitId && splitId.length > 0) {
          let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
          splitStep[idIndex] = result.col1.ibmStepName;
          this.requestImprRow.ibmStepNames = splitStep.toString();
        }
        this.$refs['tableRisk'].$refs['compo-table'].resetVirtualScroll();
      }
    },
    openHazardPicture(props) {
      this.popupOptions.title = '유해위험요인 사진';
      this.popupOptions.param = {
        ramRiskHazardId: props.row.ramRiskHazardId,
        disabled: true
      };
      this.popupOptions.target = () => import(`${'@/pages/common/hazard/riskHazardPicture.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeHazardPicturePopup;
    },
    closeHazardPicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    openImprPicture(props) {
      this.popupOptions.title = '개선 사진';
      this.popupOptions.param = {
        ramJsaAssessScenarioId: props.row.ramJsaAssessScenarioId,
        sopImprovementIds: props.row.sopImprovementIds,
        disabled: true
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/jsa/action/jsaScenarioImprPicture.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeScenarioImprPicturePopup;
    },
    closeScenarioImprPicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>